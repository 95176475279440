
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























































































































































































































































































.flexible-experts__items {
  // Grid size
  --grid-row: 15rem;
  --grid-column: 16rem;

  display: grid;
  grid-template-rows: repeat(3, var(--grid-row));
  grid-template-columns: repeat(11, var(--grid-column));
  grid-gap: 6rem 3rem;
  will-change: transform;

  @include mq(m) {
    --grid-row: 20rem;
    --grid-column: 22rem;
  }

  // @include mq(l) {
  //   --grid-row: 25rem;
  //   --grid-column: 27rem;
  // }
}

.flexible-experts__content {
  margin: $spacing * 3 0 $spacing * 2;

  @include mq(m) {
    display: none;
  }
}

.flexible-experts__intro {
  ::v-deep {
    .g-intro {
      max-width: 100%;
      margin-top: $spacing * 2;
    }
  }

  @include mq($until: m) {
    .flexible-experts__items & {
      display: none;
    }
  }

  @include mq(m) {
    grid-row: 1/2;
    grid-column: 3/5;
  }
}

.flexible-experts__item {
  border-radius: 8px;
  will-change: transform;
  cursor: pointer;

  span {
    display: block;
  }

  // First Big item
  &:first-child {
    grid-row: 1/3;
    grid-column: 1/3;
  }

  &:nth-child(2) {
    grid-row: 3/4;
    grid-column: 2/3;

    ::v-deep {
      [class*='action'][class*='--btn'] {
        padding: 0.5rem;
      }
    }
  }

  &:nth-child(3) {
    grid-row: 2/3;
    grid-column: 3/4;

    ::v-deep {
      [class*='action'][class*='--btn'] {
        padding: 0.5rem;
      }
    }
  }

  &:nth-child(4) {
    grid-row: 2/3;
    grid-column: 4/5;

    ::v-deep {
      [class*='action'][class*='--btn'] {
        padding: 0.5rem;
      }
    }
  }

  &:nth-child(5) {
    grid-row: 3/4;
    grid-column: 3/4;

    ::v-deep {
      [class*='action'][class*='--btn'] {
        padding: 0.5rem;
      }
    }
  }

  &:nth-child(6) {
    grid-row: 1/2;
    grid-column: 5/6;

    ::v-deep {
      [class*='action'][class*='--btn'] {
        padding: 0.5rem;
      }
    }
  }

  // Big item
  &:nth-child(7) {
    grid-row: 2/4;
    grid-column: 5/7;
  }

  &:nth-child(8) {
    grid-row: 2/3;
    grid-column: 7/8;

    ::v-deep {
      [class*='action'][class*='--btn'] {
        padding: 0.5rem;
      }
    }
  }

  &:nth-child(9) {
    grid-row: 3/4;
    grid-column: 7/8;

    ::v-deep {
      [class*='action'][class*='--btn'] {
        padding: 0.5rem;
      }
    }
  }

  // Big item
  &:nth-child(10) {
    grid-row: 1/3;
    grid-column: 8/10;
  }

  &:nth-child(11) {
    grid-row: 3/4;
    grid-column: 8/9;

    ::v-deep {
      [class*='action'][class*='--btn'] {
        padding: 0.5rem;
      }
    }
  }

  &:nth-child(12) {
    grid-row: 2/3;
    grid-column: 10/11;

    ::v-deep {
      [class*='action'][class*='--btn'] {
        padding: 0.5rem;
      }
    }
  }

  &:nth-child(13) {
    grid-row: 2/3;
    grid-column: 11/12;

    ::v-deep {
      [class*='action'][class*='--btn'] {
        padding: 0.5rem;
      }
    }
  }

  &:nth-child(14) {
    grid-row: 3/4;
    grid-column: 10/11;

    ::v-deep {
      [class*='action'][class*='--btn'] {
        padding: 0.5rem;
      }
    }
  }

  &:nth-child(15) {
    grid-row: 3/4;
    grid-column: 11/12;

    ::v-deep {
      [class*='action'][class*='--btn'] {
        padding: 0.5rem;
      }
    }
  }
}

.flexible-experts__item__picture {
  height: 100%;

  ::v-deep img {
    transition: transform 0.5s $ease-softer;
  }

  .flexible-experts__item:hover &,
  .flexible-experts__item:focus-visible & {
    ::v-deep img {
      transform: scale(1.05);
    }
  }
}

.flexible-experts__item__legend {
  @extend %ff-alt;
  @extend %fw-bold;

  ::v-deep em {
    @extend %ff-default;
    @extend %fw-light;
  }
}

.flexible-experts__item__button {
  position: absolute;
  z-index: 9;
  bottom: $spacing;
  left: $spacing;

  &::after {
    @include get-all-space;

    content: '';
    position: fixed;
    z-index: -1;
  }
}

.flexible-experts__item__description {
  margin-top: math.div($spacing, 2);
  color: $c-dark-grey;
  font-size: 1.8rem;
  font-style: italic;

  strong {
    font-style: normal;
  }
}

.flexible-experts__slides__nav {
  display: flex;
  justify-content: center;
  margin-top: $spacing * 3;

  @include mq(m) {
    margin-top: $spacing * 3.5;
  }
}

.flexible-experts__slides__nav__button {
  height: 4.6rem;
  margin: 0 math.div($spacing, 2);
}
